@import url(https://fonts.googleapis.com/css2?family=Overpass+Mono:wght@300;400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:200,300,700);
@import url(https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
:root {
  font-size: 16px;
  font-family: "Overpass Mono", monospace;
  font-family: var(--font1);
  position: relative;

  /* COLORS */

  --almost-white: #dad9d8;
  --white: white;
  --grey: #3a3939;
  --lightgrey: #606060;
  --almost-black: #121212;
  --black: black;
  --error: #db324d;

  /* FONTS */
  --font1: "Overpass Mono", monospace;

  /* BREAKPOINTS */
  --tabletBreakpoint: 1000px;
  --smallLaptopBreakpoint: 1441px;
  --largeScreenBreakpoint: 1919px;

  /* TRANSITIONS */

  --transition: all 200ms ease-in-out;
}

input {
  border: none;
  outline: none;
  background-color: transparent;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
button {
  border: none;
  cursor: pointer;
  background: none;
  -webkit-user-select: none;
          user-select: none;
  color: inherit;
  font: inherit;
}
button:active {
  outline: none;
}
button:focus {
  outline: 0;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

@media screen and (min-width: 1301px) {
  :root {
    font-size: 12px;
  }
}

/* .App {
  background-color: var(--main-black);
  width: 100vw;
  height: 100vh;
  color: white;
  font-size: 2rem;
  overflow: hidden;
} */

/* Temporary resolves react scripts bug */
iframe {
  z-index: -1 !important;
}

