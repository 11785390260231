/* .App {
  background-color: var(--main-black);
  width: 100vw;
  height: 100vh;
  color: white;
  font-size: 2rem;
  overflow: hidden;
} */

/* Temporary resolves react scripts bug */
iframe {
  z-index: -1 !important;
}
